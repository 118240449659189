:root {
    --dark: #44443c;
    --dark-70: rgb(68, 68, 60, .7);
    --dark-50: rgb(68, 68, 60, .5);
    --light: #fff;
    --light-70: rgb(255, 255, 255, 0.7);
    --light-50: rgb(255, 255, 255, 0.5);

    --brand: rgb(212, 170, 0, 1);
    --brand-75: rgb(212, 170, 0, .75);
    --brand-50: rgb(212, 170, 0, .50);
    --brand-25: rgb(212, 170, 0, .25);
    --brand-hover: rgb(212, 170, 0, 1);
    --light-brand: #f6eecc;
    --light-hover: #808e9b;
    --dark-hover: #485460;
    --default: #92989e;
    --default-hover: #485460;
    --info: rgb(31, 82, 129);
    --info-hover: rgb(76, 122, 165);
    --success: rgba(39, 174, 96, 1);
    --success-hover: rgb(28, 124, 68);
    --primary: rgba(98, 105, 206, 1);
    --primary-hover: rgb(79, 85, 163);
    --secondary: #00d8d6;
    --secondary-hover: #208b8a;
    --warning: rgb(241, 196, 15, 1);
    --warning-hover: rgb(192, 156, 14);
    --danger: rgb(231, 76, 60, 1);
    --danger-hover: rgb(184, 61, 48);
    --facebook-color: rgb(66, 103, 178, 1);
    --facebook-color-hover: rgb(56, 88, 153);
    --font-color: #495057;
    --silver: #e3e7eb;


    --error: var(--danger);
    --error-hover: var(--danger-hover);


    --main-bg: var(--default);
    --main-color: var(--dark);
    --main-font-family: sans-serif;

}


::placeholder {
    color: var(--dark-50) !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--dark-50) !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--dark-50) !important;
}


.bg-light, .bg-white-transparent {
    background-color: var(--light-70) !important;
}


.text-center {
    text-align: center;
}

.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3em;
    height: 100%;
    background-color: var(--light-50);
    opacity: .5;
}

.alert {
    text-align: center;
}

.btn-logout {
    display: flex;
    align-items: center;
    justify-content: center;
}



.graph {
    flex-direction: column;
    align-items: center;
    justify-content: center;


    canvas {
        max-height: 100%;
    }
}

.content {
    padding: 1rem;
}

.centered-box {
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
        width: 95%;
        max-width: 400px;
        padding: 2rem;
        border-radius: 1rem;
        position: relative;
        z-index: 2;

        &::before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .6;
            z-index: -1;
            background-color: var(--light);
        }
    }
}

.table {
    margin-bottom: 0;
}

.pagination-wrapper {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;

    .pagination {
        margin: 0;
    }
    

}


$sides: top, right, bottom, left;
@each $side in $sides {
    @for $i from 1 through 5 {
        .p#{$side}-#{$i} {
            padding-#{$side}: #{$i * 0.25}rem !important;
        }

        .m#{$side}-#{$i} {
            margin-#{$side}: #{$i * 0.25}rem !important;
        }
    }
}

$directions: left, right, center;
@each $direction in $directions {
    .text-#{$direction} {
        text-align: #{$direction};
    }
}