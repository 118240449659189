html {
    height: 100vh;
    width: 100vw;
    background-image: url(../image/login-bg.jpg);
    background-size: cover;
}

body,
#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
}



.default-template {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;

    .main-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--silver);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--dark-70);
        }
    }
}

.menu-item-collapsed {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: .5rem 1rem;

    svg {
        margin-right: .5rem;
    }
}

.top-menu-user-info {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
}

.main-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
    --side-menu-width: 250px;
}

.sidebar-wrapper {
    position: fixed;
    overflow: hidden;
    height: 100vh;
    width: var(--side-menu-width);
    -webkit-box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: var(--light);

    .sidebar {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;

        &-brand {
            width: var(--side-menu-width);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 56px;
            cursor: pointer;

            img {
                height: 1.25em;
            }
        }

        &-logout {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem 0;
        }

        &-item {
            margin: 0;
            padding: 0;
            flex-grow: 1;
            width: 100%;
            cursor: pointer;
            transition: all .3s ease-in-out;
            text-decoration: none;
            color: var(--dark-70);
            border: 1px solid var(--silver);
            font-weight: bold;
            transition: all .3s ease-in-out;


            &:hover {
                background-color: var(--light-70);
            }
        }

        &-top {
            flex-grow: 1;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        &-bottom {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
        }
    }

}

.content-wrapper {
    height: 100vh;
    width: 100%;
    padding: 1em;


    @media (min-width: 992px) {
        padding-left: calc(var(--side-menu-width) + 1em);
    }

    @media (min-width: 2500px) {
        // width: 2250px;
        width: calc(100% - var(--side-menu-width));
        margin-left: var(--side-menu-width);
        // margin: 0 auto;
        padding-left: 1em;
    }
}



.navbar-brand {
    width: var(--side-menu-width);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;

    img {
        height: 1em;
    }
}

.login-brand {
    text-align: center;
    margin-bottom: 2rem;

    img {
        width: 80%;
    }
}

.sublist {
    padding-left: 1em;
}